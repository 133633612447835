/* rastwe: 10/17/17 - I'm adding an @import here to point at the
 *  EY Online Style Guide. This is required because of some issues with
 *  The "extract-text-webpack-plugin", or webpack itself. In either case,
 *  relying on the @import order instead of webpack fixes the issue.
 *  TODO: undo this later, and just refer to the OSG in the top-level
 *  component. */
 @import '~bootstrap/dist/css/bootstrap.min.css'; 
 @import './vars';

 /* default Global font sizes */
 
 // Small devices (phones, 320px and up)
 @mobile-default: ~'only screen and (min-width : 320x) and (max-width : 575px)';
 
 // Small devices (landscape phones, 576px and up)
 @mobile-large: ~'only screen and (min-width : 576px) and (max-width : 766px)';
 
 // All mobile devices (Mobiles, 768px and below)
 @all-mobiles: ~'only screen and (max-width : 767px)';
 
 // Medium devices (tablets, 768px and up)
 @tablet-default: ~'only screen and (min-width : 768px) and (max-width : 991px)';
 
 // Large devices (desktops, 992px and up)
 @desktop-small: ~'only screen and (min-width : 992px) and (max-width : 1366px)';
 
 // desktops (large desktops, 1200px and up)
 @desktop-default: ~'only screen and (min-width : 1200px)';
 
 // Extra large desktop (Extra large desktops, 1824px and up)
 @desktop-large: ~'only screen and (min-width : 1824px)';

// Extra large desktop (Extra large desktops, 2048px and up)
@desktop-extlarge: ~'only screen and (min-width : 2048px)';
 
 // Medium desktop (1280px to 1366px)
 @desktop-medium: ~'only screen and (min-width : 1367px) and (max-width : 1600px)';

body {
    font-size: 10px !important;
    font-family: 'ey_interstate', 'EYInterstate' !important;
    overflow: hidden !important;    
    line-height: 24px !important;
}
 
* * {
     font-family: 'ey_interstate', 'EYInterstate' !important;
}

 .btn {
    font-size: 1rem;
    line-height: 1rem;    
 }
 
 input,
 textarea,
 select,
 .Select-control,
 .form-control,
 .Select-menu-outer {
     font-size: 1.6rem;
     line-height: 1.6rem;     
 }
 
 label {
     line-height: 1.6rem;
    
 }
 
 h1 {
     font-size: 2rem;
     line-height: 2rem; 
    
 }
 
 h2 {
     font-size: 2.9rem;
     line-height: 2.9rem;
 
 }
 
 h3 {
     font-size: 1.8rem;
     line-height: 1.8rem;
 }
 
 h4 {
     font-size: 1.6rem;
     line-height: 1.6rem;
 }
 
 h5,
 .add-edit-page-title {
     font-size: 1.65rem;
     line-height: 1.65rem;
 }

 h6,
 .application-name .title {
     font-size:  1.2rem;
     line-height:  1.2rem; 
 }
 

 .tiny-text {
     font-size: 1rem;
     line-height: 1rem;
 }
