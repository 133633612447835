/**
 * This file is to only contain styles which are specific to the CG
 * application. Anything overriding Online Style Guide styles should
 * go in mixins.less.
 */
body {
    overflow:hidden;
}

ul, li {
    margin: 0;
    padding: 0;
}
