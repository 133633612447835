/**
 * Global LESS variables
 */

/* Colors */
@ey-black: #000;
@ey-buttercup: #ffee99;
@ey-green: #2c973e;
@ey-green-50: #95cb89;
@ey-lilac: #ac98db;
@ey-lilac-50: #d8d2e0;
@ey-link-blue: #336699;
@ey-link-blue-50: #7fd1d6;
@ey-purple: #91278f;
@ey-purple-50: #c893c7;
@ey-red: #f04c3e;
@ey-white: #fff;
@ey-yellow: #ffd200;
@ey-orange: #f7941d;
@gray-light: #f0f0f0;
@gray-halfWhite:#eee;
@gray-20:#fcfcfc;
@gray: #c0c0c0;
@gray-ccc: #ccc;
@gray-30:#656565;
@gray-half: #81858b;
@gray-34th: #44494f;
@gray-dark: #24282d;
@gray-charcoal: #333;
@grey-light: #dedede;
@grey-50: #3b3838;
@grey-70: #7f7f7f;
@ey-sky-blue: #35b7c0;
@ey-teal: #00a3ae;
@gray-100: #44494f;
@grey-light-bg: #f4f4f4;
@gray-14th: #bec1c7;
@ey-ready-green: #00a4ae;
@ey-inqueuebadge: #fcd5b5;
@ey-inprogressbadge: #d7e4bd;
/* Layout */
@footer-height: (@nav-height / 4) + @footer-padding * 2;
@footer-padding: 5px;
@nav-height: 80px;
@screen-xs: 480px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1200px;
@label-font-weight: bold;
@cursor-disabled: default;
@labelWeightNormal: normal;
@z-index-select-dropdown: 1060; // must be higher than a modal background (1050)
//** Placeholder text color
@input-color-placeholder: #999;
@channelToolsWidth: 100%;
@baseFontSize: 12px;
@label-font-weight: bold;
@toggleButtonBorder: #d9dadb;
@toggleActiveButton: #ffe600;
@buttonBorderRadius: 4px;
@inputBorderRadius: 4px;
@textColorWhite: #fff;
@inputActiveBorder: #f7941d;
@label-font-weight: bold;
@baseVMFontSize: 0.77vw;
@label-placeholder-color: #81858b;
@formComponentHeight: 30px;
@typeaheadTokenColor: #e7f4ff;
@typeheadLabelColor: #1f8dd6;
@typeheadDisabledColor: #ddd;
@typeheadSelectedColor: #1f8dd6;
@buttonDisabled: #ddd;
@buttonActiveBorder: #bde7ea;
@opacity-transparent: 1;
@opacity-blur: 0.8;
@ey-border-radius: 4px;
@cursor-default: default;
@cursor-pointer: pointer;
@boxshadow-none: none;
@display-inline: inline-block;
@typeahead-background: #646464;
@baseFormLineHeight: 32px;
@global100Percent: 100%;
@globalRadius: 0px;
@bg-disabled: #eee;

.global-lh-pd-fs {
	padding: 0 10px;
}

// Small devices (phones, 320px and up)
@mobile-default: ~'only screen and (min-width : 320x)';

// Small devices (landscape phones, 576px and up)
@mobile-large: ~'only screen and (min-width : 576px)';

// Medium devices (tablets, 768px and up)
@tablet-default: ~'only screen and (min-width : 768px)';

// Large devices (desktops, 992px and up)
@desktop-small: ~'only screen and (min-width : 992px)';

// Large desktops (large desktops, 1200px and up)
@desktop-default: ~'only screen and (min-width : 1200px)';

// Extra large desktop (large desktops, 1824px and up)
@desktop-large: ~'only screen and (min-width : 1824px)';
