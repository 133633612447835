/**
 * main.less
 */

@import '../globals';
@import './vars';

body {
	background-color: @gray-light;
	overflow: hidden;
	font-size: 10px;
}
html {
	font-size: 10px !important;
}
html,
body,
div,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
.motif-input,
input,
button {
	font-family: 'ey_interstate', 'EYInterstate' !important;
	font-size: unset;
	border-radius: unset;
}

.k-overlay {
	background: #1a1a24 !important;
	opacity: 0.85;
	z-index: 10002 !important;
}

.k-animation-container.k-animation-container-shown {
    z-index: 10003 !important;
}

.k-window {
	// Start for kendo window modal in messaging layout js file
	&.kendowrapper {
		width: 50vw !important;
		height: calc(100vh - 15rem) !important;
		z-index: 10002 !important;
		top: 6rem !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		position: fixed;
		display: flex !important;
		margin: 0 auto;
		align-items: center;
		justify-content: center;

		.k-window-titlebar {
			width: 50vw;
		}

		.modal-footer {
			left: 0 !important;
			bottom: 0;
			z-index: 90000;
			background: #ffffff;
			position: absolute;
		}

		.k-window-content {
			width: 50vw;
			& > section:first-child {
				display: block;
			}

			.createmessagewrapper {
				display: inline-block !important;
			}
		}
	}
	// End for kendo window modal in messaging layout js file

	.k-window-content {
		overflow: hidden;
	}
	.k-window-titlebar .k-window-title {
		font-size: 1.7rem;
		font-weight: bold;
	}
	.k-edit-field{
		.k-checkbox{
			margin:0;
		}
	}
}

.btn {
	/* Override OSG styles */
	padding: 8px 18px;
	.global-lh-pd-fs;

	> .fa-2x {
		font-size: inherit;
	}

	> .fa-3x {
		font-size: inherit;
	}

	> .fa-4x {
		font-size: inherit;
	}

	> .fa-5x {
		font-size: inherit;
	}
}

.search-form.row.content.top-tools {
	box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3);
	border: none;
}

.has-feedback .form-control {
	border: 1px solid @ey-orange;
	padding: 0 @channelToolsPadding - 3px 0;
	color: @input-color-placeholder;
}

/* Loading Indicator -- Remove later*/

.loader {
	width: 100%;
	margin: 0 auto;
	text-align: center;

	progress {
		width: 60%;
		height: 0.8rem;
		margin-top: 15%;
	}
}

@keyframes move {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: 28px 0;
	}
}

.Select {
	.Select-control {
		border-radius: @globalRadius;
	}
}

.text-bold {
	font-weight: bold;
}

button[disabled],
body input[disabled] {
	background-color: #eee;
}

.Loader {
	.Loader__background {
		position: fixed !important;
	}
}

.cog-icon-disabled {
	background: url(./images/cog-wheel-disabled.svg) no-repeat left top;
	background-size: 2.4rem 2.4rem;
	width: 2.4rem;
	height: 2.4rem;
}

.cog-icon {
	background: url(./images/Cog-wheel.svg) no-repeat left top;
	background-size: 2.4rem 2.4rem;
	width: 2.4rem;
	height: 2.4rem;
}

.x-small {
	width: 1.4rem;
	height: 1.4rem;
}

.small {
	width: 1.6rem;
	height: 1.6rem;
}

.medium {
	width: 2rem;
	height: 2rem;
}

.large {
	width: 2.4rem;
	height: 2.4rem;
}

.x-large {
	width: 3rem;
	height: 3rem;
}

.MainContainerWrapper {
	background: #ffffff;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
}

/*modal related changes*/
.modal-control .modal-header h4.modal-title {
	font-size: 2.2rem;
}

.main {
	display: flex;
	flex: 1;
	flex-flow: row;
	height: calc(100vh - 6rem);
	overflow: hidden;
	background: #f5f5f5;
	.timesheet-view {
		background: #fafafc;
	}
}

.motif-tooltip .motif-tooltip-wrapper {
	margin-top: 0 !important;
}

.motif-tooltip-inner .motif-icon-button {
	display: none;
}

.motif-tooltip-content.motif-tooltip-light .motif-tooltip-inner {
	overflow: auto;
	max-height: 350px;
}

body
	.motif-timeline-content
	.motif-current-date-marker
	.motif-current-date-icon {
	z-index: 999;
}

/*form-component fix*/

.radio input {
	cursor: Pointer;
}

input:focus,
.select-value .Select-input:focus,
a:focus,
body :focus {
	box-shadow: none;
}

.Select.has-value.Select--single.is-disabled
	.Select-control
	.Select-value
	.Select-value-label {
	color: #c0c0c0;
}

.form-group .select-value .Select.is-disabled .Select-control {
	border: 0.1rem solid #ddd;
}

section div.form-group.has-feedback > section.select-value .select-label {
	color: #7f7f7f;
}

a .ellipses div:hover {
	text-decoration: underline;
	cursor: pointer;
}

.radio.disabled input {
	background: none;
}

body .Select.is-focused.Select--single.is-open > .Select-control {
	border: 0.1rem solid #c4c4cd;
}

body .overridedate .Select.is-focused.Select--single.is-open > .Select-control {
	border: 0.1rem solid #747480;
}

/*remove disabled not-allowed cursor for form elements*/
body .form-control[disabled],
body input[disabled],
body .Select.is-disabled,
body .Select.is-disabled:hover {
	cursor: default;
	pointer-events: none;
}

/*location label as per other control*/
body .form-group .select-value .select-label {
	font-size: 1rem;
}

/*remove cross from all form elements*/
body .Select-control .Select-clear {
	display: none;
}
/*remove cross from all form elements*/

.offline {
	border: 0.7rem solid #009999;
}

.training {
	border: 0.7rem solid #724bc3;
}

/*tooltip*/
body .tooltip .tooltip-inner {
	word-break: break-all;
}

/*modal footer button fix*/
body .modal-control.modal .modal-dialog .modal-footer button.btn {
	margin-right: 1rem;
}

body .modal-control .modal-dialog .modal-footer button.btn + button.btn {
	margin-left: 0;
}
/*modal footer button fix*/

.error-icon,
.success-icon,
.warning-icon {
	background: url('../../images/error-icon.svg') no-repeat 10px 10px;
	width: 1.6rem;
	height: 1.6rem;
	display: inline-block;
	padding: 18px;
	display: table-cell;
	background-color: #b9251c;
}

.success-icon {
	background: url('../../images/success-icon.svg') no-repeat 7px 7px;
	background-color: #2c973e;
}

.warning-icon {
	background: url('../../images/warning-icon.png') no-repeat 9px 7px;
	background-color: #ff9831;
}

@media @desktop-medium {
	html {
		font-size: 9px !important;
	}
}
@media @desktop-small {
	html {
		font-size: 8px !important;
	}
}

.Select.is-clearable.is-searchable.Select--single
	.Select-control
	.Select-multi-value-wrapper
	.Select-input
	> input {
	width: 35rem !important;
}

.tooltip-helixlinked .motif-tooltip {
	width: 25rem;
	.motif-tooltip-wrapper {
		width: 100%;
	}
}

.tooltip-helixlinked .motif-tooltip-inner {
	max-height: 6.95rem;
	overflow-y: auto;
	padding: 1rem 1rem 0 1rem;

	.motif-tooltip-content-children {
		width: calc(100% - 1.5rem);
	}
}

.tooltip-helixlinked ul {
	list-style: none;
	font-size: 1.2rem;
}

.tooltip-helixlinked ul li {
	margin-bottom: 0;
}

.motif-tooltip-inner {
	padding: 0.57143rem !important;

	.motif-tooltip-content-children {
		margin-right: 0.57143rem !important;
	}
}

.customScrollbar,
.motif-tooltip-inner,
.Select .Select-menu-outer .Select-menu,
.motif-select.motif-select-open .motif-select-field-list,
.show-pop-content .show-pop-content-body {
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 0.6rem;
	}

	&::-webkit-scrollbar-track {
		background: #e7e7ea;
		width: 0.6rem;
	}

	&::-webkit-scrollbar-thumb {
		background: #c4c4cd;
		width: 0.6rem;
		cursor: pointer;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #747480;
	}
}

.richText-Body P {
	font-size: 1.3rem !important;
}

.mainResponsiveWrapper {
	width: calc(100vw - 8rem);
	margin: 0 auto;
	max-width: 2048px;
}

@media @desktop-extlarge {
	.EYHeader .EYLogo {
		margin-left: 4rem;
	}
}

div.form-group {
	span.input-group {
		.input-label {
			line-height: normal;
			z-index: 9;
		}

		input.form-control {
			margin: 0 2rem 0 0;
			border: none !important;
			border-bottom: 0.1rem solid var(--form-element-border-color) !important;
			background-color: var(--form-element-bg-color) !important;
		}

		input.form-control:hover,
		input.form-control:focus {
			border-bottom: 0.2rem solid var(--form-element-border-color) !important;
		}

		input.form-control:hover + .help-block,
		input.form-control:focus + .help-block {
			top: -2px;
		}
	}
}

.auditmetrics-tooltip .motif-tooltip-inner {
	padding: 0 !important;
	margin: 0;

	.motif-tooltip-content-children {
		margin-right: 0 !important;
	}

	.motif-icon-button {
		display: block;
		right: 1rem;
		top: 1rem;
		position: absolute;
	}

	.auditmetrics-container {
		width: 800px;

		.auditmetrics-title {
			padding: 1rem 2rem;
			margin: 0;
			font-size: 1.6rem;
			font-weight: normal;
			border-bottom: 0.1rem solid #c4c4cd;
			background: #f6f6fa;
		}
	}
}

.auditmetrics-tooltip .motif-tooltip {
	border: 0.1rem solid #c4c4cd;
}

.deletion-status ~ .timelinecontrol .err-msg {
	margin-top: 0;
}

.archiveaccesslist-modal .modal-content {
	.modal-wrapper {
		height: auto !important;
	}
}

.motif-button:disabled,
.motif-text-button.motif-text-button-disabled:focus,
.motif-text-button.motif-text-button-disabled:hover,
.motif-text-alt-button.motif-text-button-disabled:focus,
.motif-text-alt-button.motif-text-button-disabled:hover {
	cursor: default !important;
}

.clientHolder .Workspaceholder .workspace-list .eng-listholder,
.clientHolder .Workspaceholder .workspace-list .engagementerror-indicator,
.clientHolder .Workspaceholder .eng-list .eng-listholder,
.clientHolder .Workspaceholder .eng-list .engagementerror-indicator,
.clientHolder
	.Workspaceholder
	.workspace-list
	.engagementUpdates.no-engagementalets,
.clientHolder .Workspaceholder .workspace-list .engagementUpdates,
.clientHolder .Workspaceholder .eng-list .engagementUpdates.no-engagementalets,
.clientHolder .Workspaceholder .eng-list .engagementUpdates,
.clientHolder
	.Workspaceholder
	.eng-list
	.engagementUpdates.no-engagementalets
	.arrow-right,
.clientHolder .Workspaceholder .eng-list .engagementUpdates .arrow-right {
	box-shadow: none;
}

.clientHolder .Workspaceholder .eng-list .engagementerror-indicator {
	float: left;
	padding: 0;
}

.modal-dialog .modal-footer {
	padding-left: 24px !important;
}

.logout-modal {
	.modal-body {
		cursor: default;

		.msg1 {
			font-size: 1.4rem;
			font-weight: bold;
			color: #2e2e38;
			margin-bottom: 3rem;

			svg {
				fill: #ff9831;
				width: 2rem;
				height: 2rem;
				vertical-align: middle;
				margin-top: -0.3rem;
				margin-right: 0.3rem;
				cursor: default;
			}
		}

		.msg2 {
			font-size: 1.4rem;
			font-weight: normal;
			color: #747480;
			margin-bottom: 0.5rem;
		}

		.msg3 {
			font-size: 1.4rem;
			font-weight: normal;
			color: #747480;
		}

		.msg4 {
			font-size: 1.4rem;
			font-weight: normal;
			color: #747480;
			margin-top: 3rem;

			.msg42 {
				font-size: 1.4rem;
				font-weight: bold;
				color: #2e2e38;
			}
		}
	}
}

.zeus-datepicker .motif-form-field .motif-label {
	position: absolute;
	top: 1.2rem;
	font-size: 1.4rem;
	font-weight: normal;
	color: #2e2e38;
	padding-left: 0.64286rem;
	-webkit-transition: top 0.2s ease, font-size 0.2s ease;
	transition: top 0.2s ease, font-size 0.2s ease;
	padding-bottom: 0.57143rem;
}
.zeus-datepicker .motif-form-field.motif-form-field-has-value .motif-label {
	top: 0.4rem;
	font-size: 1rem;
	font-weight: normal;
	color: #747480;
	width: auto;
	z-index: 1;
	padding-bottom: 0;
}
.loader-wrapper {
	position: relative;
}
.loader-wrapper .motif-loader-container {
	background-color: #000 !important;
	opacity: 0.5;
}

@media screen and (max-width: 1366px) {
	.Select {
		&.is-open .Select-menu-outer {
			max-height: 200px !important;

			& * {
				max-height: 200px !important;
			}
		}
	}
}

.motif-date-picker-wrapper input[disabled],
.motif-date-picker-wrapper
	.motif-date-picker.react-date-picker--disabled
	.react-date-picker__inputGroup__input {
	background: none !important;
}

.motif-timeline
	.motif-timeline-content
	.motif-milestone
	.motif-milestone-content,
.motif-timeline
	.motif-timeline-content
	.motif-milestone
	.motif-milestone-content
	.motif-progress-bar-circle,
.motif-timeline .motif-timeline-content .motif-date-marker p,
.motif-timeline .motif-timeline-content .motif-date-marker-year p {
	background: #f6f6fa !important;
}

.pagination .pagination {
	background: none !important;
}

.softwareupdate-modal {
	.modal-dialog .modal-body,
	.modal-content[role='document'] .modal-wrapper {
		height: auto !important;
	}

	.modal-content {
		padding-top: 15%;
	}
}

.archiveaccesslist-modal .modal-wrapper {
	max-width: calc(100vw - 40px) !important;
}

.offline .menu-nav + li {
	margin: 0rem 4rem;
	list-style: none;
	.eng-listwrapper {
		margin: 0;
	}
}

.engagementalerts-wrapper .engagement-alertsblock {
	z-index: 9;
}

body[motif-theme='dark'] {
	background: #1a1a24;
	color: #fff;

	.assignrolemodal {
		.ticketdetail {
			h5 {
				color: #ffffff;
			}
			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused) {
				.motif-label {
					color: #ffffff;
				}
			}
			.motif-form-field-has-value,
			.motif-form-field-focused {
				.motif-label {
					color: #ffffff;
				}
			}
			.motif-input {
				color: #ffffff;
			}
		}
	}

	button[disabled],
	body input[disabled] {
		background-color: #2e2e38;
	}

	.main,
	.main .timesheet-view {
		background: #1a1a24;
	}

	.form-group .select-value .Select.is-disabled .Select-control {
		border: 0.1rem solid #747480;
	}

	.form-group .select-value .Select.is-disabled .Select-control {
		border: 0.1rem solid #3a3a4a;
	}

	section div.form-group.has-feedback > section.select-value .select-label {
		color: #747480;
	}

	body .Select.is-focused.Select--single.is-open > .Select-control {
		border: 0.1rem solid #747480;
	}

	body
		.overridedate
		.Select.is-focused.Select--single.is-open
		> .Select-control {
		border: 0.1rem solid #c4c4cd;
	}

	.offline {
		border: 0.7rem solid #42c9c2;
	}

	.training {
		border: 0.7rem solid #724bc3;
	}

	.auditmetrics-tooltip .motif-tooltip-inner {
		background: #2e2e3c;
		color: #fff;

		.auditmetrics-container {
			.auditmetrics-title {
				border-bottom: 0.1rem solid #747480;
				background: #3a3a4a;
			}
		}

		.motif-icon-button svg path {
			fill: #fff;
		}
	}

	.auditmetrics-tooltip .motif-tooltip {
		border: none;
	}

	.motif-tooltip .motif-tooltip-arrow:before {
		border-color: #2e2e3c;
	}

	.motif-tooltip {
		.motif-tooltip-inner {
			background: #2e2e3c;
			color: #fff;
		}
	}

	.modal-dialog {
		.modal-header {
			background-color: #3a3a4a;
			border-color: #2e2e3c !important;

			.close,
			.modal-title {
				color: #fff !important;
			}
		}

		.modal-body {
			background-color: #23232f;
			color: #fff !important;
		}

		.modal-footer {
			background-color: #23232f;
			color: #fff !important;
			border-color: #2e2e3c !important;
		}
	}

	.logout-modal {
		.modal-body {
			.msg1 {
				color: #f6f6fa;

				svg {
					fill: #ff9831;
				}
			}

			.msg2 {
				color: #c4c4cd;
			}

			.msg3 {
				color: #c4c4cd;
			}

			.msg4 {
				color: #c4c4cd;

				.msg42 {
					color: #f6f6fa;
				}
			}
		}
	}

	.zeus-datepicker .motif-form-field .motif-label {
		color: #f6f6fa;
	}

	.zeus-datepicker .motif-form-field.motif-form-field-has-value .motif-label {
		color: #c4c4cd;
	}

	.loader-wrapper .motif-loader-container {
		background-color: #fff !important;
	}

	.motif-timeline-content .motif-progress-bar .motif-progress {
		background-color: #4ebeeb;
	}

	.motif-timeline-content .motif-key-event .motif-key-event-shape {
		background: #4ebeeb !important;
	}

	svg {
		fill: #fff !important;
	}

	.engagementUpdates .arrow-right {
		border-right: 4.5rem solid #1a1a24 !important;
		box-shadow: none;
	}

	.engagementUpdates,
	.engagementUpdates.no-engagementalets,
	.engagementalerts-wrapper .engagement-alertsblock {
		background: #3a3a4a !important;
	}

	.engagementalerts-wrapper .engagement-alertsblock {
		z-index: 9;
	}

	.btn.workspaceview_toggle.active svg {
		fill: #1a1a24;
	}

	.btn.workspaceview_toggle:hover svg {
		fill: #1a1a24;
	}

	label,
	input {
		color: #fff !important;
	}

	.page-filter .search-filter > .searchControl {
		border-color: #fff;
	}

	.pagination.pagination {
		background: #1a1a24;
		color: #fff;

		span,
		.Dropdown-control {
			color: #fff;

			.Dropdown-arrow {
				border-color: #fff !important;
			}
		}

		.Dropdown-menu {
			background-color: #2e2e3c;
			border-color: #2e2e3c;
			color: #fff;
		}
	}

	.pagination input[disabled],
	.pagination button[disabled] {
		background-color: #1a1a24;
	}

	.Select-arrow {
		border-color: #fff !important;
	}

	.Select-value-label {
		color: #fff !important;
	}

	.eng_name .ellipses {
		color: #fff !important;
	}

	button[buttontype='primary'] {
		border-color: #ffe600 !important;
		background: #ffe600 !important;
		color: #1a1a24 !important;

		&:hover {
			border-color: #eaeaf2 !important;
			background: #eaeaf2 !important;
		}
	}

	button[buttontype='secondary'] {
		border-color: #fff !important;
		color: #fff !important;
		background: #1a1a24 !important;

		&:hover {
			border-color: #ffe600 !important;
			color: #ffe600 !important;
			background: #1a1a24 !important;
		}
	}

	button[buttontype='secondary'][disabled] {
		border-color: #fff !important;
		color: #fff !important;
		background: #1a1a24 !important;

		&:hover {
			border-color: #fff !important;
			color: #fff !important;
			background: #1a1a24 !important;
		}
	}

	.btn.workspaceview_toggle.active {
		background: #fff !important;
		border-color: #fff !important;

		svg {
			fill: #1a1a24 !important;
		}

		&:hover {
			background: #1a1a24 !important;
			border-color: #fff !important;

			svg {
				fill: #fff !important;
			}
		}
	}

	.btn.workspaceview_toggle {
		background: #1a1a24 !important;
		border-color: #fff !important;

		svg {
			fill: #fff !important;
		}

		&:hover {
			background: #fff !important;
			border-color: #fff !important;

			svg {
				fill: #1a1a24 !important;
			}
		}
	}

	.popblock .editList ul li:hover a,
	.popblock .editList ul li:hover span {
		color: #ffe600;
	}

	.page-filter .pagination .Dropdown-root .Dropdown-menu .Dropdown-option:hover,
	.page-filter
		.pagination
		.Dropdown-root
		.Dropdown-menu
		.Dropdown-option:focus {
		color: #ffe600;
		background: #1a1a24;
		opacity: 1;
	}

	.tooltip-helixlinked ul li {
		color: #fff !important;

		.ellipses {
			color: #fff !important;
		}
	}

	.ellipses,
	.help-block {
		color: #fff !important;
	}

.componentTabsWrapper li {
	color: #747480 !important;

	.ellipses {
		color: #747480 !important;
	}
}

.componentTabsWrapper li.active {
	color: #fff !important;

	.ellipses {
		color: #fff !important;
	}
}
.createeditworkspacewrapper .createnewworkspace .form-group .select-value .is-disabled ~ .select-label,
.Select.has-value.Select--single.is-disabled .Select-control .Select-value .Select-value-label {
	color: #747480 !important;
}
.motif-timeline-content .motif-milestone .motif-milestone-content,
.motif-timeline-content
.motif-milestone
.motif-milestone-content
.motif-progress-bar-circle,
.motif-timeline-content .motif-date-marker p,
.motif-timeline-content .motif-date-marker-year p {
	background: #23232e !important;
}

	.eng-listwrapper > section {
		box-shadow: 1px -1px 8px 0px rgba(0, 0, 0, 0.3),
			0px 4px 8px 0px rgba(0, 0, 0, 0.3) !important;
	}

	.engagementUpdates.no-engagementalets,
	.engagementUpdates {
		box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.2) !important;
	}
	//.show-pop-content-body .NotificationsGroupContainer .ellipses,
	//.show-pop-content-body .NotificationsGroupContainer .help-block,
	.access-listtable tr .ellipses,
	.access-listtable td .ellipses,
	.access-listtable th .ellipses,
	.access-listtable tr,
	.access-listtable td,
	.access-listtable th {
		color: #fff !important;
		background: #1e1e2a !important;
	}

	.access-listtable .teammembers-table-title th {
		color: #fff !important;
		background: #1a1a24 !important;
		border: 0.1rem solid #2e2e38;
	}

	.react-select-container .react-select__menu .react-select__menu-list,
	.Select-menu-outer .Select-menu .Select-option {
		background-color: #23232f;
	}

	.Select-menu-outer .Select-menu .Select-option {
		color: #fff;
	}

	.react-select-container
		.react-select__menu
		.react-select__menu-list
		.react-select__option--is-focused,
	.react-select-container
		.react-select__menu
		.react-select__menu-list
		.react-select__option--is-selected,
	.Select-menu-outer .Select-menu .Select-option.is-selected,
	.Select-menu-outer .Select-menu .Select-option.is-focused,
	.Select-menu-outer .Select-menu .Select-option:hover {
		background-color: #2e2e3c;
		color: #ffe600 !important;

		.ellipses {
			color: #ffe600 !important;
		}
	}

	.motif-date-picker-wrapper input[disabled],
	.motif-date-picker-wrapper
		.motif-date-picker.react-date-picker--disabled
		.react-date-picker__inputGroup__input {
		background: none !important;
	}

	.Select-control .Select-input:focus {
		background: #1a1a24;
	}

	.motif-text-button-disabled.motif-text-button.clear-archivesearch {
		opacity: 0.5;
	}

	.clear-archivesearch,
	.engagementUpdates .eng-actions .motif-text-alt-button,
	.eng-rightdetail .btn,
	.eng-rightdetail .btn > * {
		color: #ffe600 !important;
		border-color: #ffe600 !important;
	}

	.motif-checkbox-checked-icon,
	.motif-checkbox-indeterminate-icon {
		fill: #1a1a24 !important;
	}

	.softwareupdate-modal .modal-dialog .modal-footer button.btn {
		border-color: #eaeaf2 !important;
		background: #eaeaf2 !important;

		&:hover {
			border-color: #ffe600 !important;
			background: #ffe600 !important;
			color: #1a1a24 !important;
		}
	}

	.modal-backdrop.in {
		opacity: 0.8;
	}

	.searchControl {
		background: #1a1a24 !important;
		border-color: #fff !important;
	}

	.commentwrapper textarea {
		background: #23232f !important;
	}

	.update-modal-confimation.msg1 {
		color: #fff;
	}

	.delmsg-header .del-msgdescp .motif-link {
		color: #ffe600 !important;
	}

	.transparent-nav .NavigationBar .EYHeader {
		.EYLogo {
			.LogoLink figure .fill-color2 {
				fill: #fff;
			}

			.ey-logo-text {
				color: #fff !important;
			}
		}

		.EYNavigation {
			.navChild:hover,
			.navAnchor:hover {
				border-bottom: 0.4rem solid #ffe600 !important;
			}

			.navChild.noBorder:hover,
			.navAnchor.noBorder:hover {
				border: none;
			}

			.navChild.selected,
			.navAnchor.selected {
				background-color: transparent;
				border-bottom: 0.4rem solid #ffe600 !important;

				.engagements,
				.archives,
				.reporting,
				.usersettings {
					svg {
						fill: #fff !important;
					}
				}

				.notificationButton {
					svg {
						fill: #fff !important;
					}
				}
			}

			.navChild,
			.navAnchor {
				.engagements,
				.archives,
				.reporting,
				.usersettings {
					svg {
						fill: #c4c4cd !important;
					}
				}

				.notificationButton {
					svg {
						fill: #c4c4cd !important;
					}
				}
			}
		}

		.MoreMenuContainer {
			.EYMoreLinksIcon {
				top: 2.1rem;

				svg {
					fill: #fff;
				}
			}

			.motif-menu-overlay {
				&.motif-menu-overlay-open {
					background: rgba(26, 26, 36, 0.7);
				}

				.motif-menu.motif-menu-alt,
				.motif-menu-nav .motif-menu-list,
				.motif-menu-nav .motif-menu-list {
					background: #3a3a4a;
					color: #fff;
				}

				.motif-menu-nav .motif-menu-list {
					.MotifMenuListWrapper .motif-menu-list-item {
						border-color: #3a3a4a !important;

						&:hover {
							border-color: #ffe600 !important;
						}

						a {
							color: #fff;

							svg {
								fill: #fff;
							}

							&.oversight svg path {
								fill: #fff;
							}
						}
					}

					.FooterWrapper {
						background-color: #1a1a23;

						.CopyrightContainer {
							color: #fff;
						}
					}
				}
			}
		}
	}

	.engagements-wrapper {
		.motif-card {
			.engagementfavourite-container .engagementfavourite-wrapper {
				background-image: linear-gradient(
					319deg,
					#3a3a4a 3rem,
					transparent 0rem
				);
			}
		}
	}

	.motif-checkbox-custom .motif-checkbox-checked-icon svg {
		fill: #1a1a24 !important;
	}

	.engagement-progress.motif-progress-bar-wrapper .motif-progress-bar {
		background-color: #3a3a4a;
	}

	.timesheet-view .MainContainerWrapper.customScrollbar {
		background: #1a1a24;

		.motif-card {
			background-color: #1a1a24;
		}
	}
}

.import-for-review-info-tooltip {
	.motif-tooltip-top {
		top: -11px !important;
		max-width: 280px;
	}

	.motif-tooltip-bottom {
		top: 11px !important;
	}

	.motif-tooltip-inner {
		padding: 1rem;
		background: rgb(255, 255, 255);
		box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
		border-radius: 0px;
		display: flex;
		align-items: center;
		padding-left: 20px !important;
		padding-right: 40px !important;
	}

	.motif-tooltip-content-children {
		margin: 0;
		font-size: 11px;
		line-height: 20px;
	}

	.motif-icon-button {
		width: 48px !important;
		height: 48px !important;
		position: absolute;
		top: 0;
		right: 0px;
		padding: 2px;
		display: block;
	}
}

body[motif-theme='dark']
	.motif-tooltip
	.motif-tooltip-inner
	.motif-icon-button {
	color: white !important;
}

.inner-top-level-import-for-review .motif-checkbox-disabled:hover {
	cursor: url('./images/notallowedgrey.svg'), auto !important;
}

.no-break-words.tooltip {
	.tooltip-inner {
		word-break: normal !important;
	}
}

.modal-open .modal {
	display: flex !important;
	align-items: center;
	min-height: calc(100% - (1.5rem * 2));
}

.modal-dialog {
	position: relative !important;
	height: auto !important;
}

.assignrolewrapper {
	.loadingstyle {
		display: flex;
		align-items: center;
		height: 61.5rem;
	}
}

.assignrole {
	padding: 0 !important;

	.modal-footer {
		display: inline-flex;
		width: 100%;
		padding: 2rem !important;

		button {
			font-size: 1.4rem;
			margin-right: 1rem;
			padding: 1.2rem 1.5rem;
			line-height: normal;
		}
	}

	.assignrolemodal {
		height: 40rem;
		font-size: 1.4rem;
		//ticketstart
		.ticketdetail {
			margin: 0 2rem 2rem 2rem;
			padding-bottom: 2rem;
			h5 {
				font-size: 1.4rem;
				font-weight: normal;
				color: #2e2e38;
				border: 0 !important;
			}

			.motif-form-field {
				width: 29rem;
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused) {
				.motif-label {
					font-size: 1.4rem;
					font-weight: normal;
					margin-top: 0.6rem;
					color: #1a1a24;
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused {
				.motif-label {
					font-size: 1.2rem !important;
					font-weight: normal !important;
					margin-top: 0.2rem !important;
					color: #1a1a24;
				}
			}

			.motif-input {
				font-size: 1.4rem;
				line-height: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				color: #1a1a24;
				&::placeholder {
					font-size: 1.4rem;
				}
			}
		}
		//ticketend

		.wrapper {
			padding: 1.6rem 2rem;

			.motif-form-field-select-input .motif-select-field-list {
				overflow: hidden !important;

				.motif-select-list-options {
					max-height: 21rem !important;
				}
			}

			.labeltext {
				font-size: 1.3rem;
				margin: 0;
				padding: 0 0 1.5rem 0;
			}

			.motif-form-field {
				font-size: 1.4rem !important;
				padding-bottom: 0.4rem;

				.motif-select-input {
					height: 4rem;
				}

				.motif-select-wrapper-options {
					.motif-select-search-input {
						font-size: 1.4rem;
					}
				}

				.motif-error-message {
					font-size: 1.4rem;
					margin-top: 0.5rem;

					svg {
						width: 1.8rem;
						height: 1.8rem;
					}
				}

				.motif-select-field {
					.motif-checkbox-label-wrap {
						margin: 0;
					}

					.motif-inner-search {
						font-size: 1.4rem;
					}

					button {
						font-size: 1.4rem !important;
						height: 4rem;
						padding-top: 1.5rem;

						svg.motif-checkbox-checked-icon {
							margin-top: 0;
						}

						svg {
							margin-top: 0.5rem;
						}
					}
				}

				.motif-label {
					font-size: 1.2rem !important;
					margin-top: 0.2rem;
				}
			}
		}
	}
}

.edit-template {
	overflow: visible;

	.motif-modal-body {
		overflow: visible;
	}
}

.motif-input-clear-button:hover,
.motif-input-clear-button:focus,
.motif-input-clear-button:focus svg,
.motif-icon-button:hover,
.motif-icon-button:focus,
.motif-icon-button:focus svg {
	box-shadow: none !important;
	outline: none !important;
}

.motif-tooltip-content {
	.motif-tooltip-content-children {
		font-size: 1.2rem;
	}
}

.deleteicon,
.editicon,
.delete {
	.motif-tooltip-inner {
		padding: 0 !important;

		.motif-tooltip-content-children {
			margin: 0 !important;

			.motif-toast-content {
				width: 100%;
				display: block;

				.motif-toast-icon-container {
					svg {
						min-width: 1.5rem;
						min-height: 1.5rem;
						margin-right: 1rem;
					}
				}

				.motif-toast-text-container {
					font-size: 1.2rem;
					width: auto !important;
					display: block;
					margin-right: 1rem;
				}
			}
		}
	}
}
#QSIFeedbackButton-target-container {
	height: calc(100% - 7rem) !important;
	top: 6.5rem;
	border: 0 !important;
}

.QSIFeedbackButton {
	z-index: 900 !important;
}

.input-group.active {
	.input-label {
		top: 4px !important;
		font-size: 10px !important;
		color: #747480 !important;
		transition: 200ms ease all !important;
	}
}

.motif-tooltip-content.archiveretry {
	.motif-tooltip {
		.motif-tooltip-arrow:before {
			bottom: -0.6em !important;
			border: 0.9rem solid #ffffff !important;
		}
	}
}

.copytoclipboard-tooltip {

	.motif-tooltip {
		width: 230px;
	}

	.motif-tooltip-inner {
		padding: 1rem;

		.motif-tooltip-content-children {
			font-size: 1.2rem;
			margin-right: 0;
			line-height: 1.6rem;
		}
	}
}
