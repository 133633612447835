@import '../vars.less';

@channelToolsPadding: 8px;
@channelToolsHeight: 20px + @channelToolsPadding * 2;
@mainPadding: 34px;
@containerWidth: 100%;

/*there is something wrong with this class. I have to add it all over to make it work. it should be in
  the common vars and work everywhere*/

.optional-tag {
	font-weight: @labelWeightNormal;
	font-style: italic;
	color: @grey-50;
	padding-left: 10px;
}
