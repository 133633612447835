@import '../../globals.less';

html {
  font-size: 10px !important;
}
body {
  font-size: 10px !important;
  font-family: 'EYInterstate' !important;
  overflow: hidden !important;
  line-height: 24px !important;
}
@media only screen and (min-width : 1367px) and (max-width : 1600px) {
  html {
	font-size: 9px !important;
  }
}
@media only screen and (min-width : 992px) and (max-width : 1366px) {
  html {
	font-size: 8px !important;
  }
}
